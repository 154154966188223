// module.exports = require('../dist/hashids.js').default

import MuzThrees from './src/muz-threes/muz-threes.vue';

/* istanbul ignore next */
MuzThrees.install = function(Vue) {
    Vue.component(MuzThrees.name, MuzThrees);
};

export default MuzThrees;
