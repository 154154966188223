<template>
    <div class="muz-threes" :id="threeId"></div>
</template>

<script>

/** 
 * @brief 通用three.js三维加载组件。
 * @warning 所有的配置全部依赖于vuex，并可分为基本配置、光照配置、物体配置三大类
 */
import * as THREE from 'three'
let OrbitControls = require('three-orbit-controls')(THREE) //视图控件，控制缩放旋转的
import Stats from 'three/examples/jsm/libs/stats.module' //性能监测，类似游戏的FPS
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader'
import {BVHLoader} from 'three/examples/jsm/loaders/BVHLoader'
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader'
import {OutlinePass} from 'three/examples/jsm/postprocessing/OutlinePass'
import {STLLoader} from 'three/examples/jsm/loaders/STLLoader.js'
import {RectAreaLightHelper} from 'three/examples/jsm/helpers/RectAreaLightHelper.js';
import {TransformControls} from 'three/examples/jsm/controls/TransformControls.js';

import {MapControls} from 'three/examples/jsm/controls/OrbitControls.js';

// // Vue3中，scene必须放在全局变量中，暂不清楚原因
// let threeScene;
// let threeCamera;
export default {
	name: 'muz-threes',
    components: {
    },
    props: {
        store: {
            type: String,
            default: '' // 'modThrees'
        },
        threeConfig: {
            type: Object,
            default: () => ({
                /** 基础配置（单位：米） */
                config: {
                    /** @brief 相机视角 */
                    fov: 70,
                    /** @brief 相机近面 */
                    near: 1,
                    /** @brief 相机远面 */
                    far: 1000,
                    /** @brief 背景颜色 */
                    // clearColor: { color: 0x000426, alpha: 1.0},
                    /** @brief 相机位置 */
                    position: {x: -20, y: 10, z: -20},
                    /** @brief 相机朝向 */
                    lookAt: {x: -19, y: 10, z: 0},
                    
                    /** @brief 启用阴影 */
                    shadow: { show: true },
                    /** @brief 性能监测 */
                    stats: { show: false },

                    /** @brief 平面坐标格助手 
                     * @desc 默认为500*500m网络，细分为50份，即每小格10平方米 */
                    grid: {
                        show: true,
                        size: 100, //尺寸
                        divisions: 10, //细分数
                    },
                    
                    /** @brief 直角坐标系助手 */
                    axes: {
                        show: true,
                        size: 200, //轴线长，rgb三色对应xyz三轴
                    },
                    
                    /** @brief 雾 */
                    fog: {
                        show: true,
                        color: 0x051041, //雾的颜色 // #051041
                        near: 0, //雾的最小距离，rgb三色对应xyz三轴
                        far: 2000, //雾的最大距离，rgb三色对应xyz三轴
                    },
                                    
                    /** @brief 视轨控制器 */
                    orbit: {                        
                        show: true,
                        mapControl: true, //是否启用为地图控制模式
                        /* 以下原生属性 */
                        target: {x: 0, y: 10, z: 0},
                        enableDamping: true, // 启用阻尼
                        dampingFactor: 0.1, // 阻尼大小
                        screenSpacePanning: false, //相机平移方式
                        minDistance: -50, //相机距原点近面
                        maxDistance: 1000, //相机距原点远面
                        keyPanSpeed: 7, //当使用键盘按键的时候，相机平移的速度有多快。默认值为每次按下按键时平移7像素。
                        maxPolarAngle: Math.PI / 2, //最大俯角
                        panSpeed: 5, //平移速度
                        rotateSpeed: 0.2, //旋转速度
                        autoRotate: false, //自动旋转
                        autoRotateSpeed: 0.2 ,//自动旋转速度
                    },
                },
                
                /** 地面贴图（单位：米） */
                grounds: [
                    // {
                    //     width: 100,
                    //     height: 100,
                    //     map: './models/grounds/ground-001.png',
                    //     position: {x: 0, y: 0, z: 0},
                    // },
                ],
                
                /** 灯光 */
                lights: [
                    /** @brief 环境光 */
                    {
                        type: 'ambient',
                        color: 0xffffff, //光的颜色
                        intensity: 1 //光强度
                    },
                    {
                        type: 'point',
                        color: 0xffffff, //光颜色
                        intensity: 1, //光强度
                        position: {x: 50, y: 20, z: 50}, // 光位置
                    },
                ],
                
                /** 各种物体 */
                object3Ds: [
                    // {
                    //     url: './models/tree/tree.gltf',
                    //     type: 'gltf',
                    //     scale: {x: 1, y: 1, z: 1},
                    //     position: {x: 0, y: 0, z: 0},
                    // },
                    // {
                    //     url: './models/mttu/MTTU-125CF-coldface.stl',
                    //     type: 'stl',
                    //     scale: {x: 1, y: 1, z: 1},
                    //     position: {x: 0, y: 0, z: 0},
                    // },
                ],
            })
        },
    },
    data(){
        return {
            threeId: 'muzThrees' + Math.round(Math.random()*10000),
            
            /** @brief 主相机 */
            camera: null,
            /** @brief 主场景 */
            scene: null,
            /** @brief 主渲染 */
            renderer: null,
            /** @brief 主控制器 */
            controls: null,
            /** @brief 主状态监视仪 */
            stats: null,
            
            /***** 人体动画相关 *****/
            boxMesh: null,
            boneMixer: null,
            humanMixer: null,
            clock: null,
            
            /***** 运动光球相关 *****/
            lightSphereMesh: null, // 发光球网格
            isUp: false,
            tracker: null,
            
            /** 气泡窗场景组 */
            bubbleScene: null,
            
            /** @brief 已加载到场景中的3D对象的索引字典 */
            object3DsDict: {},
            
            
            /** @brief 相机视迹动画专用变量 */
            cameraLastDot: [0,0,0,0,0,0,0], //相机的上次迹点 position和target
            cameraNextDot: [0,0,0,0,0,0,0], //相机的本次迹点 position和target
            cameraStartTime: -1,
            cameraEndTime: -1,
            
            /** @brief 3D物体运动画专用状态，有值则当前存在动画 */
            object3DMoveInfo: {
                // moveKeyName: { start: null /*开始时间*/,  end: null /*结束时间*/, step: 0 /*第几步移动*/, last: {x,y,z} },
            },
            
            
        }
    },
    computed:{
        state(){
            return this.store ? this.$store.state[this.store] : null
        },
        mainConfig(){
            const defaultConfig = {
                /** @brief 相机视角 */
                fov: 70,
                /** @brief 相机近面 */
                near: 1,
                /** @brief 相机远面 */
                far: 1000,
                /** @brief 相机位置 */
                position: {x: -10, y: 10, z: 0},
                /** @brief 相机朝向 */
                lookAt: {x: 0, y: 0, z: 0},
                
                /** @brief 启用阴影 */
                shadow: { show: false },
                /** @brief 性能监测 */
                stats: { show: false },
                
                /** @brief 平面坐标格助手 
                 * @desc 默认为500*500m网络，细分为50份，即每小格10平方米 */
                grid: {
                    show: true,
                    size: 100, //尺寸
                    divisions: 10, //细分数
                },
                
                /** @brief 直角坐标系助手 */
                axes: {
                    show: true,
                    size: 200, //轴线长，rgb三色对应xyz三轴
                },
                                
                /** @brief 视轨控制器 */
                orbit: {
                    show: true,
                    target: {x: 0, y: 0, z: 0},
                    minDistance: -50, //相机距原点近面
                    maxDistance: 1000, //相机距原点远面
                    keyPanSpeed: 7, //当使用键盘按键的时候，相机平移的速度有多快。默认值为每次按下按键时平移7像素。
                    maxPolarAngle: Math.PI, //最大俯角
                    panSpeed: 5, //平移速度
                    rotateSpeed: 0.2, //旋转速度
                },
            }
            return this.store ? this.state.config : this.threeConfig.config ? this.threeConfig.config : defaultConfig;
        },
        grounds(){
            return this.store ? this.state.grounds : this.threeConfig.grounds;
        },
        lights(){
            return this.store ? this.state.lights : this.threeConfig.lights;
        },
        lines(){
            return this.store ? this.state.lines || [] : this.threeConfig.lines || [];
        },
        bubbles(){
            return this.store ? this.state.bubbles || [] : this.threeConfig.bubbles || [];
        },
        tracks(){
            return this.store ? this.state.tracks : this.threeConfig.tracks;
        },
        trees(){
            return this.threeConfig.trees || [];
        },
        panoramas(){
            return this.threeConfig.panoramas || [];
        },
        object3Ds(){
            return this.store ? this.state.object3Ds : this.threeConfig.object3Ds;
        },
        custom3Ds(){
            return this.store ? this.state.custom3Ds : this.threeConfig.custom3Ds;
        },
        animations(){
            return this.store ? this.state.animations : this.threeConfig.animations;
        },
        moves(){
            return this.store ? this.state.moves : this.threeConfig.moves;
        },
        trackDict(){
            let dict = {}
            this.tracks.map(it => { dict[it.key] = it })
            return dict;
        }
    },
    mounted(){
        this.init();
        // this.testBox();
        // this.loadHuman(); // sieyoo临时注释，因为缺少模型
        this.animate();
        this.loadGround();
        this.loadLight();
        this.loadLine();
        // this.loadSprite();
        this.loadBubble();
        this.loadTree();
        this.loadVRPanorama();
        this.loadObject3Ds();
        // this.loadCustom3Ds();
        this.loadKeyboardListener();
        
        
        
        // 监听窗口变化
        window.addEventListener( 'resize', this.onWindowResize );
        
        this.renderer.domElement.addEventListener("click", (event)=>{
            this.getObjectBySite(event)
        }, false);
        
        // // 测试相机飞行
        // setTimeout( ()=> {
        //     // console.log("mounted:", this.tracks)
        //     let dots = this.tracks[0].dots;
        //     this.setCameraTrack({dots,})            
        // }, 5000)
        
        // setTimeout(() => {
        //     // console.log("object3DsDict: ", this.object3DsDict);
        //     this.setObject3DMove("外壳向上移动")
            
        // }, 5000)
        
    },
    methods:{
        init: function() {
            let container = document.getElementById(this.threeId);
            // console.log("init: container:", this.mainConfig)
            
            const c = this.mainConfig;
            // 创建透视相机
            this.camera = new THREE.PerspectiveCamera(c.fov || 50, container.clientWidth/container.clientHeight, c.near || 0.1, c.far);
            this.camera.position.set(c.position.x, c.position.y, c.position.z, );
            this.camera.lookAt(c.lookAt.x, c.lookAt.y, c.lookAt.z,);
            
            
            //创建场景
            this.scene = new THREE.Scene();
            // this.scene.background = new THREE.Color( 0xa0a0a0 );
            
            this.tracker = new Set();
            
            //添加网络 
            if(c.grid && (c.grid.show === undefined || c.grid.show)){
                // const grid2 = new THREE.GridHelper( c.grid.size, c.grid.divisions*10, 0x888888, 0xAAAAAA ) // 坐标格辅助函数(尺寸, 细分数, 中线色, 格线色)
                // grid2.position.set(0, 0, 0)
                // this.scene.add( grid2 );
                const grid = new THREE.GridHelper( c.grid.size, c.grid.divisions, 0x333333, 0x444444 ) // 坐标格辅助函数(尺寸, 细分数, 中线色, 格线色)
				grid.position.set(c.grid.position && c.grid.position.x || 0, c.grid.position && c.grid.position.y || 0, c.grid.position && c.grid.position.z || 0);
                this.scene.add( grid );
            }
                        
            //添加坐标轴
            if(c.axes && (c.axes.show === undefined || c.axes.show)){
                const axes = new THREE.AxesHelper(c.axes.size);
				axes.position.set(c.axes.position && c.axes.position.x || 0, c.axes.position && c.axes.position.y || 0, c.axes.position && c.axes.position.z || 0);
                this.scene.add(axes);
            }
                        
            //添加雾
            if(c.fog && (c.fog.show === undefined || c.fog.show)){
                this.scene.fog = new THREE.Fog( c.fog.color, c.fog.near, c.fog.far ); // #051041
            }


            //创建渲染器，并添到html容器中
            this.renderer = new THREE.WebGLRenderer({
                antialias: false, // 否执行抗锯齿。默认false, 对性能有影响
                alpha: true // 透明度 默认false
            });
            this.renderer.setPixelRatio( window.devicePixelRatio );
            this.renderer.setSize(container.clientWidth, container.clientHeight);
            this.renderer.setClearColor(c.clearColor ? c.clearColor.color : 0xFFFFFF, c.clearColor ? c.clearColor.alpha : 0.0); //背景颜色及透明度
            // 定义gammaOutput和gammaFactor
            // 版本移除：THREE.WebGLRenderer: .gammaOutput has been removed. Set WebGLRenderer.outputEncoding instead.
            // this.renderer.gammaOutput = true; 
            this.renderer.gammaFactor = 2.2;   //电脑显示屏的gammaFactor为2.2
            this.renderer.outputEncoding = THREE.sRGBEncoding;
            this.renderer.shadowMap.enabled = c.shadow !== undefined ? c.shadow.show : false ; //开启阴影
            container.appendChild(this.renderer.domElement);
            
            if(c.stats && c.stats.show){
                // 向容器中添加性能监测
                this.stats = new Stats();
                container.appendChild(this.stats.dom);
            }
            
           
            // 添加控件初始化
            if(c.orbit && (c.orbit.show === undefined || c.orbit.show)){
                if(c.orbit.mapControl)
                    this.controls = new MapControls(this.camera, this.renderer.domElement);
                else
                    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
                this.controls.enableDamping = c.orbit.enableDamping;  // 默true // 启用阻尼（惯性）
                this.controls.dampingFactor = c.orbit.dampingFactor || 0.05; // 阻尼惯性大小
                this.controls.target.set(c.orbit.target.x, c.orbit.target.y, c.orbit.target.z);
                this.controls.update();
                this.controls.screenSpacePanning = c.orbit.screenSpacePanning; //默true //定义相机平移 true:OrbitControls; false:MapControls
                this.controls.enableZoom = true;//缩放
                this.controls.enablePan = true; //右键平移拖拽
                this.controls.enableRotate = true; //右键平移拖拽
                this.controls.enableKeys = true; //启用或禁用键盘控制
                this.controls.autoRotate = c.orbit.autoRotate; // 默true //自动旋转
                this.controls.autoRotateSpeed = c.orbit.autoRotateSpeed || 2.0; //自动旋转速度 默2.0
                this.controls.minDistance = c.orbit.minDistance || 0.1; //设置相机距离原点的最近距离
                this.controls.maxDistance = c.orbit.maxDistance || 5000; //你能够将相机向外移动多少
                this.controls.keyPanSpeed = c.orbit.keyPanSpeed || 7; // 当使用键盘按键的时候，相机平移的速度有多快。默认值为每次按下按键时平移7像素。
                // this.controls.maxAzimuthAngle = ; // 自转角（水平角）有效值范围为[-2 * Math.PI，2 * Math.PI] 默认值为无穷大
                this.controls.maxPolarAngle = c.orbit.maxPolarAngle || Math.PI; // 俯瞰角（垂直角）
                this.controls.minPolarAngle = c.orbit.minPolarAngle || 0; // 俯瞰角（垂直角）
                this.controls.panSpeed = c.orbit.panSpeed || 1; // 位移的速度，其默认值为1
                this.controls.rotateSpeed = c.orbit.rotateSpeed || 1; // 旋转的速度，其默认值为1
            }
        },
        
        animate: function() {
            if(!this.scene){
                // console.log("这个动画没了:", this.store)
                return;
            }
            requestAnimationFrame(this.animate);
            
            // 更新相机飞行动画
            this.updateCameraAnimation();
            
            // 更新物体运动动画
            this.updateObject3DAnimation();
            
            // //立方体旋转
            // this.mesh.rotation.x += 0.01;
            // this.mesh.rotation.y += 0.02;
                        
            // //相机位置移动
            // this.camera.rotation.y += 0.1;
            // this.camera.position.x += 0.1;
            // this.camera.position.z += 0.1;
            
            // // 光球移动
            // let sphereY = this.lightSphereMesh.position.y;
            // if(sphereY >= 20 || sphereY <= 0.5)
            //     this.isUp = !this.isUp;
            // if(this.isUp)
            //     this.lightSphereMesh.position.y += 0.2
            // else
            //     this.lightSphereMesh.position.y -= 0.2
                
            
            //相机旋转
            // this.camera.rotation.x += 0.01;
            
            if(this.clock && this.clock.getDelta()){
                //人体混入动画
                let delta = this.clock.getDelta();
                if (this.boneMixerboneMixer)
                    this.boneMixer.update( delta );
                if (this.humanMixer)
                    this.humanMixer.update( delta );
            }
            
            this.controls.update();
            
            if(this.stats && this.stats.show)
                this.stats.update();            
        
            this.renderer.render(this.scene, this.camera);
        },
        
        /* testBox: function (){            
            //创建立方体(1立方米)，并置入场景
            const boxTexture = new THREE.TextureLoader().load( './models/textures/crate.gif', (item) => {
                // console.log("TextureLoader: boxTexture:", item)
            });
            // console.log("boxTexture:", boxTexture)
            boxTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
            let boxGeometry = new THREE.BoxGeometry(1, 1, 1);
            let boxMaterial = new THREE.MeshLambertMaterial({ map: boxTexture, transparent: true }); // 朗伯网格材质
            // let boxMaterial = new THREE.MeshNormalMaterial(); // 普通材质
            // this.controls.update()
            // 生成物体网格
            this.boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
            this.boxMesh.castShadow = true;
            this.boxMesh.position.set(0, 1 + 0.5, -2); // 中点0.5米
            this.scene.add(this.boxMesh);
            // 添加物体变换控制器
            // 参考 https://threejs.org/examples/misc_controls_transform.html
            let transformControls = new TransformControls( this.camera, this.renderer.domElement );
            transformControls.addEventListener( 'change', () => {// 原用于更新渲染器的，现在没什么用了
                // console.log("transformControls: change:", event)
            } ); 
            transformControls.addEventListener( 'dragging-changed',  (event) => {
                // console.log("transformControls:", event)
                this.controls.enabled = ! event.value;
            } );
            transformControls.attach( this.boxMesh );  
            this.scene.add(transformControls);
            
        },*/
        
        loadGround: function() {
            if(!this.grounds)
                return;
            for(const g of this.grounds){
                //添加贴图墙面
                const diffuseTex = new THREE.TextureLoader().load( g.map, (item) => {
                    // console.log("TextureLoader: diffuseTex:", item)
                } );
                const bumpTex = new THREE.TextureLoader().load( './models/textures/brick_bump.jpg', (item) => {
                    // console.log("TextureLoader: bumpTex:", item)
                } );
                // MeshBasicMaterial	基础网格材质，不受光照影响的材质
                // MeshLambertMaterial	Lambert网格材质，与光照有反应，漫反射
                // MeshPhongMaterial	高光Phong材质,与光照有反应
                // MeshStandardMaterial	PBR物理材质，相比较高光Phong材质可以更好的模拟金属、玻璃等效果
                let wallMat = new THREE.MeshLambertMaterial( {
                    map: diffuseTex,
                    // colorWrite: false,
                    // bumpMap: bumpTex,
                    // 警告：THREE.MeshLambertMaterial: 'bumpScale' is not a property of this material.
                    // bumpScale: 0.3,
                    // lightMapIntensity: 0.1, // 烘焙光的强度。默认值为1。
                    // emissiveIntensity: 0.1, // 放射光强度。调节发光颜色。默认为1。
                    // refractionRatio : 0.1, // 空气的折射率（IOR）（约为1）除以材质的折射率。默认值为0.98
                } );
                const planeGeo = new THREE.PlaneGeometry( g.width, g.height );
                const planeBack1 = new THREE.Mesh( planeGeo, wallMat );            
                planeBack1.position.set( g.position.x, g.position.y, g.position.z );
                planeBack1.lookAt( g.position.x, g.position.y + 1, g.position.z );
                planeBack1.receiveShadow = true;
                this.scene.add( planeBack1 );
            }
        },
        
        /** @brief 加载各种3D模型 */
        loadObject3Ds: function () {
            if(!this.object3Ds)
                return;
            // console.log("loadObject3Ds:", this.object3Ds)
            for(const config of this.object3Ds){
                if(config.show !== undefined && !config.show)
                    continue;
                if(config.type.toUpperCase() === 'GLTF')
                    this.loadGltf(config);
                if(config.type.toUpperCase() === 'STL')
                    this.loadStl(config);    
                if(config.type === "triangle")
                    this.loadTriangleMesh(config);
                if(config.type === "plane")
                    this.loadPlaneMesh(config);
            }
        },
        
        // // 加载各种自定义模型
        // loadCustom3Ds: function () {
        //     if(!this.custom3Ds)
        //         return;
        //     for(const object of this.custom3Ds){
        //         if(object.show !== undefined && !object.show)
        //             continue;
        //         if(object.type === "triangle")
        //             this.loadTriangleMesh(object);
        //         if(object.type === "plane")
        //             this.loadPlaneMesh(object);
        //     }
        // },
        
        // 加载灯光
        loadLight: function () {
            if(!this.lights)
                return;
            for(const light of this.lights){
                if(!light.show && light.show !== undefined)
                    continue;
                if(light.type === 'ambient')
                    this.loadAmbientLight(light);
                else if(light.type === 'point')
                    this.loadPointLight(light);
                else if(light.type === 'sun')
                    this.loadSunLight(light);
                else if(light.type === 'hemisphere')
                    this.loadHemisphereLight(light);
                else if(light.type === 'directional')
                    this.loadDirectionalLight(light);
                else if(light.type === 'spot')
                    this.loadSpotLight(light);
                else if(light.type === 'area')
                    this.loadAreaLight(light);
            }
        },
        
        // 环境光 能保持整体都是亮点
        loadAmbientLight: function (lightConfig) {
            const c = lightConfig
            let ambientLight = new THREE.AmbientLight(c.color, c.intensity) //#aaffff
            // 将灯光加入到场景中
            this.scene.add(ambientLight)
        },
        
        /** @brief 平行光 */
        loadDirectionalLight: function (lightConfig) {
            const c = lightConfig;
            const light = new THREE.DirectionalLight(c.color || 0xFFFFFF, c.intensity || 1); //#FFD020
            light.position.set(c.position.x, c.position.y, c.position.z);
            light.castShadow = true;            
            light.shadow.camera.top = c.size/2 || 5; // 投影的相对距离，默认为5（即正方体边长10）
            light.shadow.camera.bottom = -c.size/2 || -5;
            light.shadow.camera.left = -c.size/2 || -5;
            light.shadow.camera.right = c.size/2 || 5;
            light.shadow.camera.near = c.near || 10; // default
            light.shadow.camera.far = c.far || 200; // default
            light.shadow.mapSize.set(c.mapSize || 512, c.mapSize || 512); // 定义阴影贴图的宽度和高度，必须是2的平方数
            light.shadow.bias = -0.0002; // 阴影贴图偏差 （大约0.0001）可能有助于减少阴影中的伪影
            this.scene.add( light );
            
            if(c.helper && this.mainConfig && this.mainConfig.shadow && this.mainConfig.shadow.show){
                // 平行光辅助线
                let helper = new THREE.CameraHelper( light.shadow.camera );
                // 相机阴影辅助线演示地址：https://threejs.org/examples/#webgl_shading_physical
                helper.visible = true;
                this.scene.add( helper ); 
            }
            else if(c.helper){
                // 平行光辅助线
                let helper = new THREE.DirectionalLightHelper( light, c.size / 2, 0xFF0000 );
                helper.visible = true;
                this.scene.add( helper ); 
            }
        },

        // 点光源加白球
        loadPointLight: function (lightConfig) {
            const c = lightConfig;
            // 点光源加白球
            let light = new THREE.PointLight(c.color, c.intensity, c.distance || 0, c.decay || 1);
            light.castShadow = true;
            light.shadow.bias = -0.0002;
            let sphere = new THREE.Mesh( new THREE.SphereGeometry( 1, 8, 6 ), new THREE.MeshBasicMaterial( { color: 0xffffff } ) );
            sphere.position.set(c.position.x, c.position.y, c.position.z);
            sphere.add( light );
            this.scene.add( sphere );
        },

        // 载入太阳光
        loadSunLight: function (lightConfig) {
            const c = lightConfig;
            // 太阳点光源
            let light = new THREE.PointLight(c.color || 0xFFFFFF, c.intensity || 5, c.distance || 100000, c.decay || 1);
            light.castShadow = true;
            light.position.set((c.position && c.position.x) || 50000, (c.position && c.position.y) || 30000, (c.position && c.position.z) || 50000);
            this.scene.add( light );
            
            // 添加太阳光图片
            const map = new THREE.TextureLoader().load( require('./sun.jpg'), (item) => {
                // console.log("TextureLoader: diffuseTex:", item)
            } );
            const material = new THREE.SpriteMaterial( { map: map } );            
            
            const sprite = new THREE.Sprite( material );
            sprite.scale.set(10000, 10000, 1)            
            sprite.position.set( 50000, 30000, 50000 );
            // sprite.castShadow = true;
            this.scene.add( sprite );
        },
        
        // 载入半球光
        loadHemisphereLight: function (lightConfig) {
            // console.log("loadSunLight")
            const c = lightConfig;
            // 添加半球光
            const light = new THREE.HemisphereLight( c.color || 0xffffff, c.groundColor || 0xffffff, c.intensity || 1 );
            light.castShadow = true;
            light.position.set(c.position.x, c.position.y, c.position.z)
            this.scene.add( light );
            
            if(c.helper){
                const helper = new THREE.HemisphereLightHelper( light, 2000 );
                this.scene.add( helper );
                
                let sphere = new THREE.Mesh( new THREE.SphereGeometry( 1000, 8, 6 ), new THREE.MeshBasicMaterial( { color: 0xffffff } ) );
                sphere.position.set(c.position.x, c.position.y, c.position.z);
                sphere.add( light );
                this.scene.add( sphere );
            }
        },
	
        // 聚光灯
        loadSpotLight: function (lightConfig) {
            // console.log("loadSpotLight:", lightConfig)
            const c = lightConfig;
            let light = new THREE.SpotLight(c.color, c.intensity, c.distance || 0, c.angle || Math.PI/3, c.penumbra || 0, c.decay || 1);
            light.castShadow = true;
            light.position.set(c.position.x, c.position.y, c.position.z)
            this.scene.add(light);
        },
        
        // 面光源
        // 演示地址：https://threejs.org/examples/webgl_materials_envmaps_parallax.html
        loadAreaLight: function (lightConfig) {
            const c = lightConfig;
            const light = new THREE.RectAreaLight( c.color, c.intensity, c.width, c.height ); // #9aaeff
            light.position.set( c.position.x, c.position.y, c.position.z );
            light.lookAt( c.lookAt.x, c.lookAt.y, c.lookAt.z );
            // 面光源助手
            if(c.helper){
                const helper = new RectAreaLightHelper( light );
                light.add( helper );
            }
            this.scene.add( light );
        },
        
        loadLine: function (lines = []) {
            if(!lines.length)
                lines = this.lines;
            for(const item of lines){
                if(!item.show && item.show !== undefined)
                    return;
                const points = [];
                item.dots.map(dot => {
                    points.push( new THREE.Vector3( dot[0], dot[1], dot[2], ) );
                })
                
                const material = new THREE.LineBasicMaterial({
                	color: item.color || 0x0000ff,
                });                
                const geometry = new THREE.BufferGeometry().setFromPoints( points );
                
                const line = new THREE.Line( geometry, material );
                this.scene.add( line );
            }
        },

        loadVRPanorama: function (panoramas) {
            if(!panoramas)
                panoramas = this.panoramas
            for(let c of panoramas){
                // 添加VR背景
                const texture = new THREE.TextureLoader().load(c.map);
                // console.log("loadVRPanorama 2:", texture)
                var geometry = new THREE.SphereGeometry(c.radius || 5, c.width || 32, c.height || 16);
                var material = new THREE.MeshBasicMaterial({
                    map: texture, 
                    side: c.side && c.side === 'back' ? THREE.BackSide : THREE.DoubleSide, 
                    color: c.color || 0xFFFFFF,
                });
                var mesh = new THREE.Mesh(geometry, material);
                if(c.position)
                    mesh.position.set(c.position.x || 0, c.position.y || 0, c.position.z || 0)
                this.scene.add(mesh);
            }
            
        },

        /* loadSprite: function (spriteConfig){
            const c = spriteConfig;
            //添加贴图墙面
            const map = new THREE.TextureLoader().load( './models/textures/brick_diffuse.jpg', (item) => {
                // console.log("TextureLoader: diffuseTex:", item)
            } );
            const material = new THREE.SpriteMaterial( { map: map } );            
            
            const sprite = new THREE.Sprite( material );
            sprite.scale.set(10, 10, 1)            
            sprite.position.set( 0, 50, 0 );
            // sprite.castShadow = true;
            this.scene.add( sprite );
        },*/
        
        loadBubble: function (bubbles = this.bubbles){
            // 没有则初始化
            if(!this.bubbleScene){
                this.bubbleScene = new THREE.Scene();
                this.bubbleScene.name = 'bubble_group'
                this.scene.add(this.bubbleScene);
            }
            
            for(let group of bubbles){
                // console.log("loadBubble:", group);
                let radius = group.ppi ? group.width * group.ppi / 2 : (group.width || 10) * 20;
                for(let c of group.list){
                    // 添加气泡文字
                    let canvas = document.createElement('canvas');
                    canvas.width = radius*2;
                    canvas.height = radius*2;
                    let ctx = canvas.getContext('2d');
                    ctx.beginPath();
                    ctx.arc(radius,radius,radius,0,2*Math.PI);
                    ctx.fillStyle = group.backgroundColor || '#CCC';
                    ctx.fill();
                    
                    ctx.textBaseline = "middle";
                    ctx.textAlign = "center";
                    let fontSize = radius * 1.618 / c.text.length;
                    ctx.font = `normal ${fontSize}px 黑体`;            
                    ctx.fillStyle = group.color || '#333';
                    ctx.fillText(c.text, radius, radius);
                    
                    const texture = new THREE.CanvasTexture( canvas); // 画布可以理解为一张图片纹理
                    // const geometry = new THREE.PlaneGeometry(100, 100); // 矩形平面
                    const material = new THREE.SpriteMaterial({ // 设置纹理贴图
                        map: texture,
                        sizeAttenuation: true,
                    });
                    
                    
                    const sprite = new THREE.Sprite( material );
                    // 精灵图的大小默认为1个单位长度
                    sprite.scale.set(group.width, group.width, 1)
                    sprite.position.set( c.position.x, c.position.y, c.position.z );
                    
                    // 向物体添加触发的信息信息
                    if(c.emit){
                        // console.log("c.emit:", sprite)
                        sprite.name = c.text
                        sprite.userData.id = c.id 
                        sprite.userData.emit = c.emit
                        sprite.userData.raw = c.raw || {}
                        
                    }
                    
                    this.bubbleScene.add( sprite );
                }
            }
            
            
        },
        
        //创建建筑GLTF加载器，并加载模型到场景里
        loadGltf: function (objectConfig) {
            // console.log("loadGltf:", objectConfig)
            const c = objectConfig;
            if(!c.show && c.show !== undefined)
                return;
            
            // 导入并过滤Gltf文件
            this.filterGltf(c.url, c.filter).then( object3D => {
                // console.log("loadGltf 2:", c, object3D)
                    
                // object3D.castShadow = true;
                // object3D.receiveShadow = true;
                
                // 以一个object3D对象作为第一个参数的函数。
                object3D.traverse( obj => {
                    if(obj.isMesh){
                        // console.log("gltfLoader: scene", obj)
                        obj.castShadow = true;
                        obj.receiveShadow = true;
                        if(c.opacity || c.opacity === 0){
                            obj.material.transparent = true; // 开启透明。在非透明模型加载后渲染。否则可能穿透背景
                            obj.material.opacity = c.opacity;
                        }
                    }
                });
                
                // 如果没有list，则正常一个模型
                if(!c.list){
                    if(c.name)
                        object3D.name = c.name;
                    if(c.scale)
                        object3D.scale.set(c.scale.x || 1, c.scale.y || 1, c.scale.z || 1);
                    if(c.position)
                        object3D.position.set(c.position.x || 0, c.position.y || 0, c.position.z || 0);
                    if(c.rotation)
                        object3D.rotation.set(c.rotation.x || 0, c.rotation.y || 0, c.rotation.z || 0);

                    // 把模型都存放起来，便于公共调用
                    this.object3DsDict[c.id || c.url] = object3D;
                    // 把模型初始信息存起来
                    object3D['name'] = c.name ? c.name : c.url;
                    object3D['xOrigin'] = {};
                    object3D['xOrigin'].position = {};
                    object3D['xOrigin'].position.x = object3D.position.x;
                    object3D['xOrigin'].position.y = object3D.position.y;
                    object3D['xOrigin'].position.z = object3D.position.z;
                    
                    if(c.emit && !object3D.scene)
                        console.warn("loadGltf: 无法向物体添加信号触发时携带的信息，由于三维对象中无场景对象:", c, object3D)
                    // 向物体添加信号触发时携带的信息
                    if(c.emit && object3D.scene){
                        object3D.scene.children.map( (it,i) => {
                            it.userData.id = c.id
                            it.userData.emit = c.emit
                            it.userData.raw = c.raw
                        })                        
                    }
                    
                    this.scene.add( object3D ); 
                }
                // 如果有list，则进行克隆
                else{
                    const group = new THREE.Group();
                    if(c.name)
                        group.name = c.name;
                    if(c.scale)
                        object3D.scale.set(c.scale.x || 1, c.scale.y || 1, c.scale.z || 1);
                    if(c.position)
                        group.position.set(c.position.x || 0, c.position.y || 0, c.position.z || 0);
                    if(c.rotation)
                        group.rotation.set(c.rotation.x || 0, c.rotation.y || 0, c.rotation.z || 0);
                    // 把模型都存放起来，便于公共调用
                    this.object3DsDict[c.id || c.url] = group;
                    c.list.map( it => {
                        const objectClone = object3D.clone();
                        if(it.position)
                            objectClone.position.set(it.position.x || 0, it.position.y || 0, it.position.z || 0);
                        if(it.rotation)
                            objectClone.rotation.set(it.rotation.x || 0, it.rotation.y || 0, it.rotation.z || 0);
                        group.add(objectClone);
                    })
                    this.scene.add( group ); 
                }
            });
        },
        
        // 导入并过滤Gltf文件
        async filterGltf (url, type = null) {
            let object3D= null;
            // console.log("filterGltf:", url, type);
            //创建建筑GLTF加载器，并加载建筑模型到场景里
            var gltfLoader = new GLTFLoader();
            
            // console.log("dracoLoader:", DRACOLoader);
            var dracoLoader = new DRACOLoader();
            // dracoLoader.setDecoderPath( '/draco/gltf/' ); //设置解码器所在的路径 '/examples/js/libs/draco/'
            // console.log("dracoLoader:", dracoLoader);
            gltfLoader.setDRACOLoader( dracoLoader );
            // 载入glTF资源
            await new Promise( (resolve,reject) =>{
                gltfLoader.load(url, gltf => {
                    // console.log("filterGltf 2:", url, type, gltf);
                    // 不过滤，返回全部scene
                    if(!type) {
                        object3D = gltf.scene;
                        resolve()
                    }
                    // 是否只过滤网格类型
                    else if(type.toLowerCase() === 'mesh'){
                        // 以一个object3D对象作为第一个参数的函数。
                        // 遍历所有对象
                        let meshes =[];
                        gltf.scene.traverse( obj => {
                            if(obj.isMesh)
                                meshes.push(obj);
                        });
                        if(meshes.length > 1){
                            object3D = new THREE.Group();
                            meshes.map(it => { object3D.add(it) })
                        }
                        if(meshes.length === 1)
                            object3D = meshes[0];
                        resolve()
                    }
                    reject()
                },);
            })
            return object3D;
        },
	
        //创建建筑STL加载器，并加载模型到场景里
        loadStl: function (objectConfig) {
            console.log("loadStl:", objectConfig)
            const o = objectConfig;
            if(!o.show && o.show !== undefined)
                return;
            //创建建筑GLTF加载器，并加载建筑模型到场景里
            var stlLoader = new STLLoader();
            // 载入stl资源
            stlLoader.load(o.url, (stl) => {
                // console.log("stlLoader:", stl);
                //创建纹理
                var mat = new THREE.MeshLambertMaterial({color: 0x00ffff});
                var mesh = new THREE.Mesh(stl, mat);
                if(o.scale) 
                    mesh.scale.set(o.scale.x || 1, o.scale.y || 1, o.scale.z || 1);
                if(o.rotation)
                    mesh.rotation.set(o.rotation.x || 0, o.rotation.y || 0, o.rotation.z || 0);
                stl.center(); //居中显示
                this.scene.add(mesh);
            },);
        },
        
        //创建建筑GLTF加载器，并加载模型到场景里
        loadTree: function (trees) {
            // console.log("loadTree:", trees)
            // 没有则初始化
            if(!this.treeScene){
                this.treeScene = new THREE.Scene();
                this.treeScene.name = 'tree_group'
                this.scene.add(this.treeScene);
            }
            if(!trees){
                trees = this.trees;
            }
            
            trees.map( treeConfig => {
                const c = treeConfig;
                // console.log("loadTree:", c)
                if(!c.show && c.show !== undefined)
                    return;
                
                this.filterGltf(c.url, 'mesh').then( meshes => {
                    // console.log("loadTree 2:", meshes)
                    meshes.map( mesh => {
                        if(c.name)
                            mesh.name = c.name;
                        if(c.scale)
                            mesh.scale.set(c.scale.x || 1, c.scale.y || 1, c.scale.z || 1);
                        if(c.position)
                            mesh.position.set(c.position.x || 0, c.position.y || 0, c.position.z || 0);
                        if(c.rotation)
                            mesh.rotation.set(c.rotation.x || 0, c.rotation.y || 0, c.rotation.z || 0);
                        
                        mesh.castShadow = true;
                        mesh.receiveShadow = true;
                         
                        if(c.list){
                            c.list.map( it => {
                                const meshClone = mesh.clone();
                                if(it.position)
                                    meshClone.position.set(it.position.x, it.position.y, it.position.z);
                                this.treeScene.add(meshClone);
                            })
                        }
                        
                    })
                });
                
            })
            
        },
        
        loadTriangleMesh: function (objectConfig) {
            // console.log("loadTriangleMesh:", objectConfig)
            const o = objectConfig;
            if(!o.show && o.show !== undefined)
                return;
            if(!o.vertices || !o.vertices.length || o.vertices.length % 3 !== 0)
                return;
            
            const geometry = new THREE.BufferGeometry().setAttribute( 'position', new THREE.BufferAttribute( new Float32Array( o.vertices ), 3 ) );
            const material = new THREE.MeshBasicMaterial( { color: 0xAA0000, side: THREE.DoubleSide, } );
            const mesh = new THREE.Mesh( geometry, material );
            this.scene.add(mesh);
        },
        
        /** 最早是士兵走路用的底板 用来接受阴影 */
        loadPlaneMesh: function (objectConfig) {
            // console.log("loadPlaneMesh:", objectConfig)
            const o = objectConfig;
            if(!o.show && o.show !== undefined)
                return;
            
            const mesh = new THREE.Mesh( 
                new THREE.PlaneGeometry( o.width, o.height ), //添加形状
                new THREE.MeshPhongMaterial({ // 添加材质
                    color: o.color, 
                    map: o.map ? new THREE.TextureLoader().load(o.map) : null, //添加纹理
                    // bumpMap: o.bumpMap ? new THREE.TextureLoader().load(o.bumpMap) : null,
                    depthWrite: false,
                    side: THREE.DoubleSide,
                    bumpScale: 1,
                })
            );
            mesh.position.set(o.position.x, o.position.y, o.position.z ); // 地面木板高1.05米
            mesh.rotation.set(o.rotation.x, o.rotation.y, o.rotation.z ); //  = -Math.PI / 2;
            mesh.receiveShadow = true;
            this.scene.add( mesh );
        },
        
        loadHuman: function (objectConfig) {
            this.clock = new THREE.Clock();
            // console.log("clock:", this.clock)
            //创建士兵模型
            const humanLoader = new GLTFLoader();
            let humanScene; // 人的场景
            let humanSkeleton; // 人的骨架
            humanLoader.load( './models/soldier.glb', (gltf) => {
                // console.log("humanLoader:", gltf)
                // gltf.scene.scale.set(2,2,2);
                gltf.scene.position.set(5, 0, 5); // 地面木板高1.05米
                humanScene = gltf.scene;
                this.scene.add( humanScene );
                
                // 以一个object3D对象作为第一个参数的函数。
                humanScene.traverse( obj => {
                    if(obj.isMesh){
                        // console.log("humanScene", obj)
                        obj.castShadow = true;
                    }
                });
                
                // //添加骨架结构
                // humanSkeleton = new THREE.SkeletonHelper(humanScene);
                // this.scene.add( humanSkeleton );
                
                // 调用面板
                // createPanel();
                // [0]:Idle空虚; [1]:Run跑步; [2]:TPose十字站; [3]:Walk散步; 
                const humanAnimations = gltf.animations;
                // 人的混合器
                this.humanMixer = new THREE.AnimationMixer( humanScene );
                /*准备一组动作*/
                //获取跑步的动作，回返一个AnimationClip类型
                let humanAction = this.humanMixer.clipAction( humanAnimations[1] );
                // activateAllActions(); 其实是执行以下4行
                humanAction.enabled = true;
                humanAction.setEffectiveTimeScale( 1 ); // 设置时间比例以及停用所有的变形
                humanAction.setEffectiveWeight( 1 );  // 设置权重以及停止所有淡入淡出
                humanAction.play();                     // 让混合器激活动作。此方法可链式调用
                // 也可以用这一句代替
                // this.humanMixer.clipAction( humanAnimations[1] ).setEffectiveWeight(1.0).play();
            
            } );
        },
        
        onWindowResize: function (){
            let container = document.getElementById('muzThrees');
        
        	this.camera.aspect = container.clientWidth/container.clientHeight;
        	this.camera.updateProjectionMatrix();
        
        	this.renderer.setSize( container.clientWidth, container.clientHeight );
        
        },
        
        onAnimation: function (e){
            // console.log("onAnimation 1: ", e, this.animations);
            if(!e.raw || !e.raw.animation_id)
                return;
            const index = this.animations.findIndex(it => e.raw.animation_id === it.id );
            if(index === -1)
                return;
            const c = this.animations[index];
            // console.log("onAnimation 2: ", c);de
            if(c.finished === undefined)
                c.finished = false;
            
            // 开始按步骤执行动画
            c.steps.map(it => {
                if(it.type === 'move'){
                    setTimeout(() => {
                        this.setObject3DMove({key: it.key, renew: c.finished ? true : false });
                    }, it.delay || 0)
                }
                else if(it.type === 'track'){
                    setTimeout(() => {
                        this.setCameraTrack({key: it.key });
                    }, it.delay || 0)
                    
                }
            })
            c.finished = !c.finished;
            
        },
        
        /** @brief 设置入口，相机飞机轨迹（多点轨迹）*/
        setCameraTrack: function ({key, dots, orbit}){
            // console.log("setCameraTrack 1:", this.trackDict)
            if(!dots && key){
                dots = this.trackDict[key]['dots'];
            }
            // console.log("setCameraTrack 2:", dots, orbit)
            if(!dots || dots.length <= 0)
                return;
            
            this.setCameraTrackByDot(dots[0]);
            
            let i = 0;            
            let loopFn = () => {
                setTimeout( ()=>{                    
                    ++i;
                    // console.log("loopFn:", i)
                    this.setCameraTrackByDot(dots[i]);
                    
                    if(i+1 <= dots.length){
                        loopFn()
                    }
                }, dots[i][6])
            };
            loopFn();
            
        },
        
        /** @brief 循环设置的下一个轨迹点 */
        setCameraTrackByDot: function (dot = []){
            if(dot.length != 7)
                return;
            this.cameraLastDot = [
                this.camera.position.x, this.camera.position.y, this.camera.position.z, 
                this.controls.target.x, this.controls.target.y, this.controls.target.z,
                0,
            ]
            this.cameraNextDot = dot;
            
            this.cameraStartTime = new Date().getTime();
            this.cameraEndTime = this.cameraStartTime + this.cameraNextDot[6];
            // console.log("setCameraTrack:", this.cameraStartTime, this.cameraEndTime, JSON.stringify(this.cameraLastDot), JSON.stringify(this.cameraNextDot))
            
        },
        
        /** @brief 设置的物体的动画，然后等待this.updateObject3DAnimation()动画被调用 */
        setObject3DMove: function ({key, renew}) {
            // 定义key在移动配置集的下标
            const index = this.moves.findIndex(it=> it.key === key); 
            // 检查边界：key是否存在
            if(index === -1) 
                return;
            // 定义配置
            const c = this.moves[index]; 
            // 检查边界：配置是否可用
            if(!c.dots || c.dots.length === 0) 
                return;
            // 定义物体
            const object3D = this.object3DsDict[c.object3D]; 
            // console.log("setObject3DMove 1:", key, object3D, renew, object3D.scene.isMoved)
            // 检查边界：3D物体是否存在
            if(!object3D) 
                return;
            
            // 声明是否移动过
            if(object3D.scene.isMoved === undefined) 
                object3D.scene.isMoved = false;
            // 原神归位！
            if(renew && object3D.scene.xOrigin.position){
                // console.log("setObject3DMove 合歙一", )
                // 边界检查，如果有相关动画先删除
                if(this.object3DMoveInfo[key])
                    delete this.object3DMoveInfo[key]
                object3D.scene.position.x = object3D.scene.xOrigin.position.x;
                object3D.scene.position.y = object3D.scene.xOrigin.position.y;
                object3D.scene.position.z = object3D.scene.xOrigin.position.z;
                object3D.scene.isMoved = false;
                return;
            }
            // // 如果物理已移动且动画已完成，或如果物理未移动且动画未完成，则不再执行
            // if((c.moved && !renew) || (!c.moved && renew) )
            //     return
            // console.log("setObject3DMove 2:", c)
            // 单次绝对移动。仅当端点为1，且时间未设置或为0时，
            if(c.absolute && c.dots.length === 1 && !c.dots[0][3]){
                object3D.scene.position.x = object3D.scene.position.x + c.dots[0][0];
                object3D.scene.position.y = object3D.scene.position.y + c.dots[0][1];
                object3D.scene.position.z = object3D.scene.position.z + c.dots[0][2];
                object3D.scene.isMoved = true;
                return;
            }
            // 单次相对移动。仅当端点为1，且时间未设置或为0时，
            else if(!c.absolute && c.dots.length === 1 && !c.dots[0][3]){
                object3D.scene.position.x += c.dots[0][0];
                object3D.scene.position.y += c.dots[0][1];
                object3D.scene.position.z += c.dots[0][2];
                object3D.scene.isMoved = true;
                return;
            }
            // console.log("setObject3DMove 3:", c)
            // 配置按点移动
            const now = new Date().getTime();
            this.object3DMoveInfo[key] = {
                start: now, /*开始时间*/
                end: now + (c.dots[0][3] || 0), /*结束时间*/
                step: 1, /*第几步移动*/
                last: {
                    x: object3D.scene.position.x,
                    y: object3D.scene.position.y,
                    z: object3D.scene.position.z,
                }
            }
            object3D.scene.isMoved = true;
            
        },
        
        /** @brief 更新相机视角动画 */
        updateCameraAnimation (){
            let now = new Date().getTime();
            if(now < this.cameraEndTime){
                let k = (this.cameraEndTime - now) / (this.cameraEndTime - this.cameraStartTime);
                let s = this.cameraLastDot;
                let e = this.cameraNextDot;                
                this.camera.position.x = e[0] - (e[0] - s[0]) * k;
                this.camera.position.y = e[1] - (e[1] - s[1]) * k;
                this.camera.position.z = e[2] - (e[2] - s[2]) * k;
                this.controls.target.x = e[3] - (e[3] - s[3]) * k;
                this.controls.target.y = e[4] - (e[4] - s[4]) * k;
                this.controls.target.z = e[5] - (e[5] - s[5]) * k;
                
                // console.log("animate:", now, JSON.stringify(this.camera.position), JSON.stringify(this.controls.target), )
            }
            // 随机性bug，因为电脑来不及渲染，可能会导致最后一刻未到达镜头终点，所以需要加0.5s时间用于计算终点
            else if(now < this.cameraEndTime + 500){                
                let e = this.cameraNextDot;                
                this.camera.position.x = e[0];
                this.camera.position.y = e[1];
                this.camera.position.z = e[2];
                this.controls.target.x = e[3];
                this.controls.target.y = e[4];
                this.controls.target.z = e[5];
            }
        },
        
        /** @brief 更新3D物体运动动画 */
        updateObject3DAnimation (){
            const now = new Date().getTime();
            for(const key in this.object3DMoveInfo){
                // 当前移动的时间状态
                const current = this.object3DMoveInfo[key];
                // 当前移动的原配置
                const moveConfig = this.moves[this.moves.findIndex(it=> it.key === key)];
                const c = moveConfig; // 别名，简化代码长度
                // console.log("updateObject3DAnimation:", now, current, c);
                // 超时但有剩余步骤，执行下一步
                if(now > current.end && current.step < c.dots.length){
                    current.step += 1;
                }
                // 溢出检查：超时且已经是最后一步
                if(now > current.end && current.step >= c.dots.length){
                    delete this.object3DMoveInfo[key];
                    continue;
                }
                
                const object3D = this.object3DsDict[c.object3D]
                // 溢出检查：找不到物体
                if(!object3D)
                    continue;
                
                const x = c.dots[current.step - 1][0];
                const y = c.dots[current.step - 1][1];
                const z = c.dots[current.step - 1][2];
                const duration = c.dots[current.step - 1][3] || 0;
                const k = duration !== 0 ? (now - current.start) / duration : 1;
                
                // console.log("updateObject3DAnimation:", k, y, JSON.stringify(object3D.scene.position));
                object3D.scene.position.x = current.last.x + k * x;
                object3D.scene.position.y = current.last.y + k * y;
                object3D.scene.position.z = current.last.z + k * z;
                
            }
        },
        
        /** @brief 添加键盘控制 */
        loadKeyboardListener: function (){
            window.addEventListener( 'keydown', function ( event ) {
                // console.log("loadListener:", event)
                switch ( event.key ) {
                    case 'w':
                    case 'ArrowUp':
                        // console.log("loadListener: 上!", )
                        break;
                        
                    case 'a':
                    case 'ArrowLeft':
                        // console.log("loadListener: 左!", )
                        break;
                        
                    case 'd':
                    case 'ArrowRight':
                        // console.log("loadListener: 右!", )
                        break;
                        
                    case 's':
                    case 'ArrowDown':
                        // console.log("loadListener: 下!", )
                        break;
        
        
                }
        
            } );
        
            window.addEventListener( 'keyup', ( event ) => {
                switch ( event.keyCode ) {
                    case 16: // Shift
                        // this.control.setTranslationSnap( null );
                        // this.control.setRotationSnap( null );
                        // this.control.setScaleSnap( null );
                        break;
        
                }
        
            } );
        },
        
        /** @brief 获取模型的鼠标触发事件 */
        getObjectBySite: function (event) {
            let intersects = null;
            const { camera } = this;
            const mouse = new THREE.Vector2();
            const raycaster = new THREE.Raycaster();
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            raycaster.setFromCamera(mouse, camera);
            if(!this.scene || this.scene.children)
                return false;
            // 过滤需要产生交互的物体
            const clickObject3Ds = this.scene.children.filter(root => {
                let hasEmit = false;
                root.children.map(it => {
                    if(it.userData.emit)
                        hasEmit = true;
                })
                return hasEmit;
            })
            intersects = raycaster.intersectObjects(clickObject3Ds, true);
            // console.log("getObjectBySite 1:", this.scene.children.map( it => it.name), this.scene.children)
            // console.log("getObjectBySite 2:", clickObject3Ds.map( it => it.name), clickObject3Ds)
            // console.log("getObjectBySite 3:", intersects[0].object.name, intersects[0].object.userData.id, intersects[0].object)
            if (!intersects[0] || !intersects[0].object) 
                return false;
            
            const currentObj =  intersects[0].object
            let emit = null;
            if (emit = currentObj.userData.emit)
                this.$emit(currentObj.userData.emit, currentObj.userData)
            // 动画信号，直接转到动画任务方法
            if(emit === 'animation')
                this.onAnimation(currentObj.userData)
            // 轨迹信号，直接转到相机轨迹方法
            else if(emit === 'track' && currentObj.userData.raw && currentObj.userData.raw.track )
                this.setCameraTrack({key: currentObj.userData.raw.track})
            
            return currentObj;
        },
        
        
        
        
        
        
        
        
        
        
        
        track(obj) {
            if (!obj) return obj;
            if (Array.isArray(obj)) {
              obj.forEach(item => this.track(item));
              return obj;
            }
        
            if (obj.dispose || obj instanceof THREE.Object3D) {
              this.tracker.add(obj);
            }
        
            if (obj instanceof THREE.Object3D) {
              this.track(obj.geometry);
              this.track(obj.material);
              this.track(obj.children);
            } 
            else if (obj instanceof THREE.Material) {
              for (const value of Object.values(obj)) {
                if (value instanceof THREE.Texture) this.track(value);
              }
        
              if (obj.uniforms) {
                for (const value of Object.values(obj.uniforms)) {
                  if (value) {
                    const uniformValue = value.value;
                    if (uniformValue instanceof THREE.Texture || Array.isArray(uniformValue)) {
                      this.track(uniformValue);
                    }
                  }
                }
              }
            }
            return obj;
        },
        
        // 清除模型
        clear() {
            // console.log("clear 1:", this.scene);
            // const { object, animationID } = this;
            const gl = this.renderer.domElement.getContext('webgl');
            gl && gl.getExtension('WEBGL_lose_context').loseContext();
            // cancelAnimationFrame(animationID);
        
            this.track(this.scene.children);
            this.scene.clear();
            this.scene.remove();
            this.renderer.clear();
            this.renderer.dispose();
            this.renderer.forceContextLoss();
            this.renderer.content = null;
            this.renderer.domElement = null;
            this.renderer.info.reset();
            this.tracker.forEach(item => {
                if (item.dispose) item.dispose();
                if (item instanceof THREE.Object3D) this.scene.remove(item);
            });
            
            // console.log("clear 2:", this.renderer.info); 
            
            this.scene = null;
            this.camera = null;
            this.composer = null;
            this.controls = null;
            this.effectFXAA = null;
            this.outlinePass = null;
            this.dracoLoader = null;
            this.labelRenderer = null;
            this.tracker.clear();
            // this.object.remove();
            // this.object = null;
            this.renderer = null;
            // console.log("clear 3:", this.scene);
        },
    },
    
    beforeDestroy (){
        // console.log("beforeDestroy:", this.scene);
        // for(let group of this.scene.children){            
        //     // 递归遍历组对象group释放所有后代网格模型绑定几何体占用内存
        //     group.traverse(function(obj) {
        //         if (obj.type === 'Mesh') {
        //             // console.log("name:", obj.name)
        //             obj.geometry.dispose();
        //             obj.material.dispose();
        //         }
        //     })
        //     // 删除场景对象scene的子对象group
        //     this.scene.remove(group);
        // }
        
    },
    destroyed() {
        // console.log("destroyed:", this.scene);
        // this.scene.clear();
        // this.renderer.dispose()
        // this.renderer.forceContextLoss()
        // this.renderer.context = null
        // this.renderer.domElement = null
        // this.renderer = null
        this.clear()
    },     
    watch:{
        threeConfig(val){
            // console.log("watch: threeConfig:", val);
            // this.clear()
            // this.init();
            // this.animate();
            this.loadGround();
            // this.loadLight();
            this.loadObject3Ds();
            // this.loadKeyboardListener();
        },
    }
}
</script>

<style>
.muz-threes {width: 100%; height: 100%; }
</style>
